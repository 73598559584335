export class QuoteItem {
    public line: string;

    public order_num: string;

    public model: string;

    public description: string;

    public quantity: number;

    public accessories: boolean;

    public warranty: number;
    
    constructor(line: string, order_num: string, model: string, description: string, quantity: number, accessories: boolean, warranty: number) {
        this.line = line;
        this.order_num = order_num;
        this.model = model;
        this.description = description;
        this.quantity = quantity;
        this.accessories = accessories;
        this.warranty = warranty;
    }
}