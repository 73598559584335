import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) { }

  get(url: string, id: any, params?: any, headers?: any) {
    return this.http.get(`${environment.apiUrl}/${url}/${id}`, { headers: headers, params: params });
  }

  getAll(url: string, params?: any, headers?: any) {
    return this.http.get(`${environment.apiUrl}/${url}`, { headers: headers, params: params });
  }

  post(url: string, body: any, params?: any, headers?: any) {
    return this.http.post(`${environment.apiUrl}/${url}`, body, { headers: headers, params: params });
  }
}