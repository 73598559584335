import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

declare var jQuery: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  searchForm: FormGroup;

  constructor(private builder: FormBuilder) {
    this.searchForm = this.builder.group({
      search: ['', [Validators.required]]
    });
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    /* Menu events */
    function menuClose() {
      jQuery('body').removeClass('popup-mobile-menu-wrapper');
      jQuery('html').css({ overflow: '' });
    };

    jQuery('.popup-mobile-click').on('click', function () {
      jQuery('body').addClass('popup-mobile-menu-wrapper');
      jQuery('html').css({ overflow: 'hidden' });
    });

    jQuery('.mobile-close').on('click', function (event) {
      event.preventDefault();
      menuClose();
    });

    jQuery('.popup-mobile-visiable').on('click', function (event) {
      event.target === this && menuClose();
    });

    jQuery('.object-custom-menu > li.has-mega-menu > a').on('click', function () {
      jQuery(this).siblings('.object-submenu').slideToggle('400');
      jQuery(this).toggleClass('active').siblings('.object-submenu').toggleClass('is-visiable');
    });

    jQuery('a.mega-menu-item').on('click', function () {
      menuClose();
    });

    jQuery('.play-modal-video').modalVideo({
      youtube: {
        controls: 1,
        nocookie: true
      }
    });
  }
}