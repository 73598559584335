// For more colors go to https://mdbootstrap.com/docs/jquery/css/colors/

declare var jQuery: any;

export class Alerts {
    static showDangerAlert(description: any = 'Ha ocurrido un error inesperado. Por favor, recarga la página para intentar solucionarlo.') {
        jQuery.toast({
            bgColor: '#CC0000',
            heading: '¡Error!',
            hideAfter: 5000,
            icon: 'error',
            loaderBg: '#FF4444',
            text: description,
            textColor: 'white',
        });
    }

    static showDefaultAlert(description: any) {
        jQuery.toast({
            bgColor: '#002597',
            heading: '¡Importante!',
            hideAfter: 5000,
            icon: 'info',
            loaderBg: '#1565C0',
            text: description,
            textColor: 'white',
        });
    }

    static showInfoAlert(description: any) {
        jQuery.toast({
            bgColor: '#0099CC',
            heading: '¡Importante!',
            hideAfter: 5000,
            icon: 'info',
            loaderBg: '#33B5E5',
            text: description,
            textColor: 'white',
        });
    }

    static showSuccessAlert(description: any) {
        jQuery.toast({
            bgColor: '#007E33',
            heading: '¡Realizado!',
            hideAfter: 5000,
            icon: 'success',
            loaderBg: '#00C851',
            text: description,
            textColor: 'white',
        });
    }

    static showWarningAlert(description: any) {
        jQuery.toast({
            bgColor: '#FF8800',
            heading: '¡Advertencia!',
            hideAfter: 5000,
            icon: 'warning',
            loaderBg: '#FFBB33',
            text: description,
            textColor: 'white',
        });
    }
}