import { Injectable } from '@angular/core';
import { Property } from '../models/property';

declare var jQuery: any;

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  // Sensors
  readonly inductiveSensor: Property[] = [{ name: 'case_diameter', description: 'Diámetro del Sensor' }, { name: 'proximity_line', description: 'Línea de Proximidad' }, { name: 'sensing_range', description: 'Rango de Sensado' }, { name: 'mounting_type', description: 'Montaje' }, { name: 'power_supply', description: 'Fuente de Alimentación' }, { name: 'output_type', description: 'Tipo de Salida' }, { name: 'connection_type', description: 'Conector' }, { name: 'case_material', description: 'Material del Sensor' }];
  readonly capacitiveSensor: Property[] = [{ name: 'case_diameter', description: 'Diámetro del Sensor' }, { name: 'proximity_line', description: 'Línea de Proximidad' }, { name: 'sensing_range', description: 'Rango de Sensado' }, { name: 'mounting_type', description: 'Montaje' }, { name: 'power_supply', description: 'Fuente de Alimentación' }, { name: 'output_type', description: 'Tipo de Salida' }, { name: 'connection_type', description: 'Conector' }, { name: 'case_material', description: 'Material del Sensor' }];
  readonly ultrasonicSensor: Property[] = [{ name: 'type', description: 'Tipo' }, { name: 'sensing_face_diameter', description: 'Diámetro Sensing Face' }, { name: 'function', description: 'Función' }, { name: 'sensing_range', description: 'Rango de Sensado' }, { name: 'resolution', description: 'Resolución' }, { name: 'response_time', description: 'Tiempo de Respuesta' }, { name: 'output_type', description: 'Tipo de Salida' }, { name: 'connection_type', description: 'Conector' }, { name: 'power_supply', description: 'Fuente de Alimentación' }];
  readonly photoelectricSensor: Property[] = [{ name: 'optic_function', description: 'Función Óptica' }, { name: 'light_emission', description: 'Emisión de Luz' }, { name: 'sensing_range_max', description: 'Rango de Sensado' }, { name: 'setting', description: 'Configuración' }, { name: 'controlled_height', description: 'Control de Altura' }, { name: 'power_supply', description: 'Fuente de Alimentación' }, { name: 'output_type', description: 'Tipo de Salida' }, { name: 'connection_type', description: 'Conector' }, { name: 'case_form', description: 'Forma del Sensor' }, { name: 'case_material', description: 'Material del Sensor' }, { name: 'protection', description: 'Protección' }, { name: 'series', description: 'Serie' }];
  readonly encoder: Property[] = [{ name: 'encoder_type', description: 'Tipo de Encoder' }, { name: 'case_diameter', description: 'Diámetro del Encoder' }, { name: 'shaft_type', description: 'Tipo de Eje' }, { name: 'shaft_diameter', description: 'Diámetro del Eje' }, { name: 'resolution', description: 'Resolución' }, { name: 'output_type', description: 'Tipo de Salida' }, { name: 'connection_type', description: 'Conector' }];
  readonly wire: Property[] = [{ name: 'connector', description: 'Conector' }, { name: 'wires', description: 'Hilos' }, { name: 'connector_type', description: 'Tipo de Conector' }, { name: 'material', description: 'Material' }, { name: 'length', description: 'Longitud' }, { name: 'shield', description: 'Blindaje' }];

  // Stationary Industrial Scanners
  readonly matrix120: Property[] = [{ name: 'code_reading', description: 'Tipo de Lector' }, { name: 'maximum_work_distance', description: 'Distancia Máxima de Trabajo' }, { name: 'minimum_work_distance', description: 'Distancia Mínima de Trabajo' }, { name: 'max_field_of_view', description: 'Campo Máximo de Visión' }, { name: 'communication', description: 'Comunicación' }, { name: 'options', description: 'Características' }];
  readonly matrix220: Property[] = [{ name: 'application', description: 'Aplicación' }, { name: 'maximum_work_distance', description: 'Distancia Máxima de Trabajo' }, { name: 'minimum_work_distance', description: 'Distancia Mínima de Trabajo' }, { name: 'speed', description: 'Velocidad' }, { name: 'options', description: 'Características' }, { name: 'input_power', description: 'Fuente de Alimentación' }];
  readonly matrix300N: Property[] = [{ name: 'application', description: 'Aplicación' }, { name: 'maximum_work_distance', description: 'Distancia Máxima de Trabajo' }, { name: 'minimum_work_distance', description: 'Distancia Mínima de Trabajo' }, { name: 'liquid_lens', description: 'Enfoque Electrónico' }, { name: 'options', description: 'Características' }, { name: 'input_power', description: 'Fuente de Alimentación' }];
  readonly matrix410N: Property[] = [{ name: 'application', description: 'Aplicación' }, { name: 'contrast', description: 'Contraste' }, { name: 'maximum_work_distance', description: 'Distancia Máxima de Trabajo' }, { name: 'minimum_work_distance', description: 'Distancia Mínima de Trabajo' }, { name: 'preconfigured_to', description: 'Preconfigurado Para' }, { name: 'color_light', description: 'Color del Iluminador' }];

  // Vision Systems
  readonly smartCamera: Property[] = [
    { name: 'Cámaras Inteligentes', description: [{ name: 'resolution', description: 'Resolución' }, { name: 'imager_type', description: 'Color / Escala de Grises' }, { name: 'software', description: 'Software' }] },
    { name: 'Iluminación', description: [{ name: 'type_light', description: 'Tipo de Luz' }, { name: 'color', description: 'Color' }] },
    { name: 'Lentes', description: [{ name: 'lens_mm', description: 'Lente (MM)' }] }
  ];
  readonly processor: Property[] = [
    { name: 'Procesadores', description: [{ name: 'port', description: 'Puertos para Cámaras' }, { name: 'max_resolution', description: 'Resolución Máxima' }, { name: 'io_type', description: 'I/O Type' }] },
    { name: 'Dongles', description: [{ name: 'license', description: 'Licencia' }] },
    { name: 'Cámaras', description: [{ name: 'type', description: 'Tipo' }, { name: 'resolution', description: 'Resolución' }, { name: 'imager_type', description: 'Color / Escala de Grises' }] },
    { name: 'Iluminación!', description: [{ name: 'type_light', description: 'Tipo de Luz' }, { name: 'color', description: 'Color' }, { name: 'size', description: 'Tamaño' }] },
    { name: 'Lentes!', description: [{ name: 'lens_mm', description: 'Lente (MM)' }, { name: 'sensor_size', description: 'Tamaño del Sensor' }, { name: 'lenses_type', description: 'Tipo' }] }
  ];
  readonly visionSensor: Property[] = [
    { name: 'Sensores de Visión', description: [{ name: 'max_operating_distance', description: 'Distancia Máxima de Operación' }, { name: 'illuminator', description: 'Iluminador' }] }
  ];

  // Laser Marking Systems
  readonly laserMarker: Property[] = [{ name: 'technology', description: 'Tecnología' }, { name: 'wave_length', description: 'Longitud de Onda' }, { name: 'watt', description: 'Potencia' }, { name: 'marking_area', description: 'Área de Marcado' }, { name: 'all_in_one', description: 'All In One' }, { name: 'version', description: 'Versión' }];

  // Safety
  readonly lightCurtain: Property[] = [{ name: 'type', description: 'Tipo' }, { name: 'resolution', description: 'Resolución' }, { name: 'monitored_height', description: 'Altura de Monitoreo' }, { name: 'max_operating_distance', description: 'Distancia Máxima de Operación' }, { name: 'restart_mode', description: 'Restart Mode' }, { name: 'system_configuration', description: 'System Configuration' }, { name: 'edm', description: 'EDM' }, { name: 'muting_function', description: 'Muting Function' }, { name: 'integrated_muting_arms', description: 'Integrated Muting Arms' }, { name: 'integrated_muting_lamp', description: 'Integrated Muting Lamp' }, { name: 'zero_dead_zone', description: 'Cero Zonas Muertas' }, { name: 'cascade', description: 'Cascada' }, { name: 'blanking', description: 'Blanking' }, { name: 'programmable', description: 'Programable' }];
  readonly laserScanner: Property[] = [{ name: 'model_sls', description: 'Modelo' }, { name: 'max_safe_distance', description: 'Distancia Máxima de Seguridad' }, { name: 'encoder_input', description: 'Entrada para Encoder' }, { name: 'max_simultaneous_zones', description: 'Número Máximo de Zonas de Seguridad Simultáneas' }, { name: 'max_warning_zones', description: 'Número Máximo de Zonas de Advertencia Simultáneas' }, { name: 'safety_outputs_ossd', description: 'Salidas de Seguridad OSSD' }];

  // Mobile Computers
  readonly falconX4: Property[] = [{ name: 'operating_system', description: 'Sistema Operativo' }, { name: 'reader', description: 'Tipo de Lector' }, { name: 'handheld', description: 'Handheld' }, { name: 'keyboard', description: 'Teclado' }];
  readonly memor1: Property[] = [{ name: 'google_mobile_service', description: 'Google Mobile Service (GMS)' }, { name: 'type', description: 'Tipo' }];
  readonly memor10: Property[] = [{ name: 'google_mobile_service', description: 'Google Mobile Service (GMS)' }, { name: 'color', description: 'Color' }, { name: 'reader', description: 'Tipo de Lector' }];
  readonly memor20: Property[] = [{ name: 'wifi', description: 'Wi-Fi' }, { name: 'color', description: 'Color' }];
  readonly memorK: Property[] = [{ name: 'operating_system', description: 'Sistema Operativo' }, { name: 'camera', description: 'Cámara' }, { name: 'battery', description: 'Batería' }, { name: 'keyboard', description: 'Teclado' }];
  readonly rhino2: Property[] = [{ name: 'operating_system', description: 'Sistema Operativo' }, { name: 'size', description: 'Tamaño de Pantalla' }, { name: 'power', description: 'Fuente de Alimentación' }, { name: 'environment', description: 'Ambiente de Trabajo' }];
  readonly skorpioX4: Property[] = [{ name: 'operating_system', description: 'Sistema Operativo' }, { name: 'reader', description: 'Tipo de Lector' }, { name: 'handheld', description: 'Handheld' }, { name: 'keyboard', description: 'Teclado' }];
  readonly taskBook: Property[] = [{ name: 'operating_system', description: 'Sistema Operativo' }, { name: 'size', description: 'Tamaño de Pantalla' }, { name: 'camera', description: 'Cámara' }];

  // Hand Held Scanners
  readonly handScanner: Property[] = [{ name: 'type', description: 'Tipo' }, { name: 'reader', description: 'Tipo de Lector' }, { name: 'connection', description: 'Conectividad' }, { name: 'interface', description: 'Interfaz de Comunicación' }, { name: 'engine', description: 'Engine (Lector)' }];

  // RFID Systems
  readonly rfid: Property[] = []; // [{ name: 'type', description: 'Tipo' }];

  constructor() { }

  public shakeIcon(): void {
    jQuery('#open-close-icon').effect('shake', { direction: 'left', times: 5, distance: 3 }, 1000);
  }

  public removeSpecial(value: string): string {
    value = String(value);

    let map = { '…': 'e', '<': 'l', '>': 'g', '(': 'f', ')': 'r', '≤': 's', 'µ': 'u', '+': 'p' };
    
    Object.keys(map).forEach(e => {
      value = value.replace(e, map[e]);
    });
    
    return value.replace(/[^0-9a-zA-Z._]/g, '').toLowerCase();
  }

  public replaceImage(element: any): void {
    jQuery(element).attr('src', 'assets/img/resources/not_available.jpg');
  }

  public getProperties(type: string): Property[] {
    var product_type = type.toLowerCase();
    switch (product_type) {
      case 'inductive_sensor':
        return this.inductiveSensor;
      case 'capacitive_sensor':
        return this.capacitiveSensor;
      case 'ultrasonic_sensor':
        return this.ultrasonicSensor;
      case 'photoelectric_sensor':
        return this.photoelectricSensor;
      case 'encoder':
        return this.encoder;
      case 'wire':
        return this.wire;
      case 'matrix_120':
        return this.matrix120;
      case 'matrix_220':
        return this.matrix220;
      case 'matrix_300n':
        return this.matrix300N;
      case 'matrix_410n':
        return this.matrix410N;
      case 'smart_camera':
        return this.smartCamera;
      case 'processor':
        return this.processor;
      case 'vision_sensor':
        return this.visionSensor;
      case 'laser_marker':
        return this.laserMarker;
      case 'light_curtain':
        return this.lightCurtain;
      case 'laser_scanner':
        return this.laserScanner;
      case 'falcon_x4':
        return this.falconX4;
      case 'memor_1':
        return this.memor1;
      case 'memor_10':
        return this.memor10;
      case 'memor_20':
        return this.memor20;
      case 'memor_k':
        return this.memorK;
      case 'rhino_2':
        return this.rhino2;
      case 'skorpio_x4':
        return this.skorpioX4;
      case 'task_book':
        return this.taskBook;
      case 'hand_scanner':
        return this.handScanner;
      case 'desk_reader':
      case 'pocket_reader':
      case 'portal_reader':
      case 'temperature_logger':
        return this.rfid;
      default: return [];
    }
  }
}