import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';

declare var jQuery: any;

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @ViewChild('modal', { static: false }) modal;

  @Output() onClose = new EventEmitter<void>();
  
  @Input() large: boolean = false;

  @Input() title: string = '';
  
  constructor() { }

  ngOnInit(): void { }

  open(): void {
    jQuery(this.modal.nativeElement).modal('show');
  }

  close(): void {
    jQuery(this.modal.nativeElement).modal('hide');
  }

  dismiss() {
    this.onClose.emit();
  }
}