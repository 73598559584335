import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './layout/main/main.component';
import { HomeComponent } from './pages/home/home.component';
import { SensorsComponent } from './pages/sensors/sensors.component';
import { StationaryIndustrialScannersComponent } from './pages/stationary-industrial-scanners/stationary-industrial-scanners.component';
import { VisionSystemsComponent } from './pages/vision-systems/vision-systems.component';
import { LaserMarkingSystemsComponent } from './pages/laser-marking-systems/laser-marking-systems.component';
import { SafetyComponent } from './pages/safety/safety.component';
import { MobileComputersComponent } from './pages/mobile-computers/mobile-computers.component';
import { HandHeldScannersComponent } from './pages/hand-held-scanners/hand-held-scanners.component';
import { RfidSystemsComponent } from './pages/rfid-systems/rfid-systems.component';
import { CartComponent } from './pages/cart/cart.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        data: { title: 'Cotizador Datalogic' }
      },
      {
        path: 'sensors',
        component: SensorsComponent,
        data: { title: 'Cotizador Datalogic Sensores' }
      },
      {
        path: 'stationary-industrial-scanners',
        component: StationaryIndustrialScannersComponent,
        data: { title: 'Cotizador Datalogic Lectores Fijos' }
      },
      {
        path: 'vision-systems',
        component: VisionSystemsComponent,
        data: { title: 'Cotizador Datalogic Visión Artificial' }
      },
      {
        path: 'laser-marking-systems',
        component: LaserMarkingSystemsComponent,
        data: { title: 'Cotizador Datalogic Marcadores Láser' }
      },
      {
        path: 'safety',
        component: SafetyComponent,
        data: { title: 'Cotizador Datalogic Seguridad' }
      },
      {
        path: 'mobile-computers',
        component: MobileComputersComponent,
        data: { title: 'Cotizador Datalogic Terminales Móviles' }
      },
      {
        path: 'hand-held-scanners',
        component: HandHeldScannersComponent,
        data: { title: 'Cotizador Datalogic Escáneres de Mano' }
      },
      {
        path: 'rfid-systems',
        component: RfidSystemsComponent,
        data: { title: 'Cotizador Datalogic RFID' }
      },
      {
        path: 'cart',
        component: CartComponent,
        data: { title: 'Cotizador Datalogic Carrito' }
      },
      {
        path: 'contact-us',
        component: ContactUsComponent,
        data: { title: 'Cotizador Datalogic Contacto' }
      }
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: { title: 'Cotizador Datalogic 404 No Encontrado' }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }