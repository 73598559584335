import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { CarouselComponent } from 'src/app/components/carousel/carousel.component';
import { SidebarComponent } from 'src/app/components/sidebar/sidebar.component';
import { Property } from 'src/app/models/property';
import { ApiService } from 'src/app/api/api.service';
import { CartService } from 'src/app/services/cart.service';
import { GlobalService } from 'src/app/services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { Alerts } from 'src/app/utils/alerts';
import { QuoteItem } from 'src/app/models/quote-item';

declare var jQuery: any;

@Component({
  selector: 'app-vision-systems',
  templateUrl: './vision-systems.component.html',
  styleUrls: ['./vision-systems.component.scss']
})
export class VisionSystemsComponent implements OnInit, AfterViewInit {
  lines: any = { 'Cámaras Inteligentes': 'smart_camera', 'Iluminación': 'lights', 'Lentes': 'lenses', 'Procesadores': 'processor', 'Dongles': 'dongles', 'Cámaras': 'processor_cameras', 'Iluminación!': 'processors_lights', 'Lentes!': 'processors_lenses', 'Sensores de Visión': 'vision_sensor' };

  @ViewChild('carousel', { static: false }) carousel: CarouselComponent;

  @ViewChild('sidebar', { static: false }) sidebar: SidebarComponent;

  selectedProperties: Property[] = [];

  properties: Property[] = [];

  products: any[] = [];

  results: any[] = [];

  constructor(private api: ApiService, private cart: CartService, public global: GlobalService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.sidebar.openSidebar();
    }, 500);
  }

  setProperties(type: string): void {
    this.spinner.show();
    this.api.post('set-properties', { product_type: type }).pipe(finalize(() => {
      this.spinner.hide();
    })).subscribe((response: any) => {
      this.properties = this.global.getProperties(type);
      this.results = response;
      setTimeout(() => {
        jQuery('#collapse-0').collapse('show');
      }, 250);
    }, () => {
      Alerts.showDangerAlert();
    });
  }

  updateProperties(property: string): void {
    this.spinner.show();
    var form: FormData = new FormData();
    this.selectedProperties.forEach(element => {
      form.append(element.name, element.description);
    });
    this.api.post('filter-properties', form).pipe(finalize(() => {
      if (this.selectedProperties.length < 2) {
        this.spinner.hide();
        this.products = [];
      } else {
        this.api.post('find-products', form).pipe(finalize(() => {
          this.spinner.hide();
        })).subscribe((response: any[]) => {
          this.products = response;
          setTimeout(() => {
            jQuery('#collapse-y').collapse('show');
            this.global.shakeIcon();
            this.openTab(property);
          }, 250);
        }, () => {
          Alerts.showDangerAlert();
        });
      }
    })).subscribe((response: any) => {
      Object.keys(response).forEach(key => {
        var a: [] = this.results[key];
        var b: [] = response[key];
        var difference = a.filter(x => !b.includes(x));
        jQuery(`input[type="radio"][name="${key}"]`).prop({ checked: false, disabled: false });
        difference.forEach(element => {
          jQuery(`input[type="radio"][name="${key}"][value="${element}"]`).prop('disabled', true);
        });
      });
    }, () => {
      Alerts.showDangerAlert();
    });
  }

  resetProperties(i: number): void {
    var properties = this.properties[i].description;
    properties.forEach(p => {
      jQuery(`input[type="radio"][name="${p.name}"]`).prop({ checked: false, disabled: false });
      var index = this.selectedProperties.findIndex(s => s.name === p.name);
      if (index >= 0) {
        this.selectedProperties.splice(index, 1);
      }
    });
    jQuery(`#collapse-${i}`).collapse('show');
    this.products[i] = [];
  }

  addProperty(element: any): void {
    var val = jQuery(element).val();
    var name = jQuery(element).prop('name');
    if (val) {
      if (name === 'product_type') {
        this.selectedProperties = [];
        this.selectedProperties.push({ name: name, description: val });
        this.setProperties(val);
      } else {
        jQuery(`input[type="radio"][name="${name}"]`).prop('disabled', true);
        this.selectedProperties.push({ name: name, description: val });
        this.updateProperties(name);
      }
    }
  }

  removeProperty(selected: string, i: number): void {
    var index = this.selectedProperties.findIndex(property => property.name === selected);
    jQuery(`#collapse-${i}`).collapse('show');
    this.selectedProperties.splice(index, 1);
    this.updateProperties(selected);
  }

  filterProperties(i: number): Property[] {
    var properties = this.properties[i].description;
    return this.selectedProperties.filter(x => properties.some(y => x.name == y.name));
  }

  addToCart(i: number, j: number): void {
    var property = this.lines[this.properties[i].name];
    var product = this.products[i][j];
    var item = new QuoteItem(property, product.order_num, product.model, product.description, 1, false, 0);
    this.cart.add(item);
  }

  openTab(property: string): void {
    let index = 0;
    for (index; index < this.properties.length; index++) {
      var exists = this.properties[index].description.some(function (e) {
        return e.name === property;
      });

      if (exists)
        break;
    }
    setTimeout(() => {
      jQuery(`#collapse-z-${index}`).collapse('show');
    }, 750);
  }

  withoutSpecial(text: string): string {
    return text.replace(/!/gi, '');
  }
}