import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { CarouselComponent } from 'src/app/components/carousel/carousel.component';
import { SidebarComponent } from 'src/app/components/sidebar/sidebar.component';
import { Property } from 'src/app/models/property';
import { Product } from 'src/app/models/product';
import { ApiService } from 'src/app/api/api.service';
import { CartService } from 'src/app/services/cart.service';
import { GlobalService } from 'src/app/services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { Alerts } from 'src/app/utils/alerts';
import { QuoteItem } from 'src/app/models/quote-item';

declare var jQuery: any;

@Component({
  selector: 'app-rfid-systems',
  templateUrl: './rfid-systems.component.html',
  styleUrls: ['./rfid-systems.component.scss']
})
export class RfidSystemsComponent implements OnInit, AfterViewInit {
  @ViewChild('carousel', { static: false }) carousel: CarouselComponent;

  @ViewChild('sidebar', { static: false }) sidebar: SidebarComponent;

  selectedProperties: Property[] = [];

  products: Product[] = [];

  constructor(private api: ApiService, private cart: CartService, public global: GlobalService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.sidebar.openSidebar();
    }, 500);
  }

  updateProperties(): void {
    this.spinner.show();
    var form: FormData = new FormData();
    this.selectedProperties.forEach(element => {
      form.append(element.name, element.description);
    });
    this.api.post('find-products', form).pipe(finalize(() => {
      this.spinner.hide();
    })).subscribe((response: Product[]) => {
      this.products = response;
      setTimeout(() => {
        jQuery('#collapse-z').collapse('show');
        this.global.shakeIcon();
      }, 250);
    }, () => {
      Alerts.showDangerAlert();
    });
  }

  resetProperties(): void {
    jQuery('input[type="radio"]').prop({ checked: false, disabled: false });
    jQuery('#collapse-x').collapse('show');
    this.selectedProperties = [];
    this.products = [];
  }

  addProperty(element: any): void {
    var val = jQuery(element).val();
    var name = jQuery(element).prop('name');
    if (val) {
      this.selectedProperties = [];
      this.selectedProperties.push({ name: name, description: val });
      this.updateProperties();
    }
  }

  addToCart(i: number): void {
    var property = this.selectedProperties.find(property => property.name === 'product_type');
    var product = this.products[i];
    var item = new QuoteItem(property.description, product.order_num, product.model, product.description, 1, false, 0);
    this.cart.add(item);
  }
}