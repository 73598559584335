import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { Router } from '@angular/router';

declare var jQuery: any;

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit, AfterViewInit {
  @ViewChild('modal', { static: false }) modal: ModalComponent;

  @Input() hiddenUrl: string = '';

  path: string = '';

  constructor(private router: Router) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      jQuery('#slick-carousel').slick({
        dots: true,
        slidesToShow: 6,
        centerMode: true,
        draggable: false,
        responsive: [
          {
            breakpoint: 980, // tablet breakpoint
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 480, // mobile breakpoint
            settings: {
              slidesToShow: 2
            }
          }
        ],
        prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>'
      });
    }, 500);

    jQuery('#slick-carousel').on('click', '.slick-slide', (e) => {
      let url = '/' + jQuery(e.currentTarget).find('> * > *').data('url');
      this.confirm(url);
    });
  }

  confirm(url: string): void {
    this.path = url;
    this.modal.open();
  }

  cancel(): void {
    this.path = '';
    this.modal.close();
  }

  redirect(): void {
    this.modal.close();
    this.router.navigate([this.path]);
  }
}