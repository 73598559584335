import { Component, OnInit, AfterViewInit } from '@angular/core';

declare var jQuery: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit {
  today: number = Date.now();

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    jQuery('#tooltip').tooltip({
      html: true,
      animated: 'fade',
      placement: 'top',
      delay: { 'show': 0, 'hide': 0 }
    });
  }
}