import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { GlobalService } from './services/global.service';
import { CartService } from './services/cart.service';

import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { MainComponent } from './layout/main/main.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ModalComponent } from './components/modal/modal.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { HomeComponent } from './pages/home/home.component';
import { SensorsComponent } from './pages/sensors/sensors.component';
import { SafetyComponent } from './pages/safety/safety.component';
import { VisionSystemsComponent } from './pages/vision-systems/vision-systems.component';
import { LaserMarkingSystemsComponent } from './pages/laser-marking-systems/laser-marking-systems.component';
import { StationaryIndustrialScannersComponent } from './pages/stationary-industrial-scanners/stationary-industrial-scanners.component';
import { MobileComputersComponent } from './pages/mobile-computers/mobile-computers.component';
import { RfidSystemsComponent } from './pages/rfid-systems/rfid-systems.component';
import { HandHeldScannersComponent } from './pages/hand-held-scanners/hand-held-scanners.component';
import { CartComponent } from './pages/cart/cart.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MainComponent,
    SidebarComponent,
    ModalComponent,
    CarouselComponent,
    NotFoundComponent,
    HomeComponent,
    SensorsComponent,
    SafetyComponent,
    VisionSystemsComponent,
    LaserMarkingSystemsComponent,
    StationaryIndustrialScannersComponent,
    MobileComputersComponent,
    RfidSystemsComponent,
    HandHeldScannersComponent,
    CartComponent,
    ContactUsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(options)
  ],
  providers: [
    GlobalService,
    CartService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }