import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { QuoteItem } from 'src/app/models/quote-item';
import { CartService } from 'src/app/services/cart.service';

declare var jQuery: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @ViewChild('sidebar', { static: false }) sidebar;

  @Input() title: string = '¿Cómo Funciona?';

  @Input() generic: boolean = true;

  observable$: Observable<QuoteItem[]>;

  isOpen: boolean = false;

  constructor(private cart: CartService) {
    this.observable$ = this.cart.getCart();
  }

  ngOnInit(): void { }

  openSidebar(): void {
    if (!jQuery(this.sidebar.nativeElement).hasClass('open')) {
      jQuery(this.sidebar.nativeElement).addClass('open');
    }
    this.isOpen = true;
  }

  toggleSidebar(): void {
    jQuery(this.sidebar.nativeElement).toggleClass('open');
    this.isOpen = !this.isOpen;
  }

  closeSidebar(): void {
    if (jQuery(this.sidebar.nativeElement).hasClass('open')) {
      jQuery(this.sidebar.nativeElement).removeClass('open');
    }
    this.isOpen = false;
  }
}