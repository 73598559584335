import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { CarouselComponent } from 'src/app/components/carousel/carousel.component';
import { SidebarComponent } from 'src/app/components/sidebar/sidebar.component';
import { Property } from 'src/app/models/property';
import { Product } from 'src/app/models/product';
import { ApiService } from 'src/app/api/api.service';
import { CartService } from 'src/app/services/cart.service';
import { GlobalService } from 'src/app/services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { Alerts } from 'src/app/utils/alerts';
import { QuoteItem } from 'src/app/models/quote-item';

declare var jQuery: any;

@Component({
  selector: 'app-laser-marking-systems',
  templateUrl: './laser-marking-systems.component.html',
  styleUrls: ['./laser-marking-systems.component.scss']
})
export class LaserMarkingSystemsComponent implements OnInit, AfterViewInit {
  @ViewChild('carousel', { static: false }) carousel: CarouselComponent;

  @ViewChild('sidebar', { static: false }) sidebar: SidebarComponent;

  selectedProperties: Property[] = [];

  properties: Property[] = [];

  products: Product[] = [];

  results: any[] = [];

  constructor(private api: ApiService, private cart: CartService, public global: GlobalService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.sidebar.openSidebar();
    }, 500);
  }

  setProperties(type: string): void {
    this.spinner.show();
    this.api.post('set-properties', { product_type: type }).pipe(finalize(() => {
      this.spinner.hide();
    })).subscribe((response: any) => {
      this.properties = this.global.getProperties(type);
      this.results = response;
      setTimeout(() => {
        jQuery('#collapse-0').collapse('show');
      }, 250);
    }, () => {
      Alerts.showDangerAlert();
    });
  }

  updateProperties(): void {
    this.spinner.show();
    var form: FormData = new FormData();
    this.selectedProperties.forEach(element => {
      form.append(element.name, element.description);
    });
    this.api.post('filter-properties', form).pipe(finalize(() => {
      if (this.selectedProperties.length < 4) {
        this.spinner.hide();
        this.products = [];
      } else {
        this.api.post('find-products', form).pipe(finalize(() => {
          this.spinner.hide();
        })).subscribe((response: Product[]) => {
          this.products = response;
          setTimeout(() => {
            jQuery('#collapse-z').collapse('show');
            this.global.shakeIcon();
          }, 250);
        }, () => {
          Alerts.showDangerAlert();
        });
      }
    })).subscribe((response: any) => {
      Object.keys(response).forEach(key => {
        var a: [] = this.results[key];
        var b: [] = response[key];
        var difference = a.filter(x => !b.includes(x));
        jQuery(`input[type="radio"][name="${key}"]`).prop({ checked: false, disabled: false });
        difference.forEach(element => {
          jQuery(`input[type="radio"][name="${key}"][value="${element}"]`).prop('disabled', true);
        });
      });
    }, () => {
      Alerts.showDangerAlert();
    });
  }

  resetProperties(): void {
    jQuery('input[type="radio"]').prop({ checked: false, disabled: false });
    jQuery('#collapse-x').collapse('show');
    this.selectedProperties = [];
    this.properties = [];
    this.products = [];
    this.results = [];
  }

  addProperty(element: any): void {
    var val = jQuery(element).val();
    var name = jQuery(element).prop('name');
    if (val) {
      if (name === 'product_type') {
        this.selectedProperties = [];
        this.selectedProperties.push({ name: name, description: val });
        this.setProperties(val);
      } else {
        jQuery(`input[type="radio"][name="${name}"]`).prop('disabled', true);
        this.selectedProperties.push({ name: name, description: val });
        this.updateProperties();
      }
      var index = this.properties.findIndex(property => property.name === name);
      if (index >= 0 && index < this.properties.length - 1) {
        jQuery(`#collapse-${index + 1}`).collapse('show');
      }
    }
  }

  removeProperty(i: number): void {
    var index = this.properties.findIndex(property => property.name === this.selectedProperties[i].name);
    jQuery(`#collapse-${index}`).collapse('show');
    this.selectedProperties.splice(i, 1);
    this.updateProperties();
  }

  addToCart(i: number): void {
    var property = this.selectedProperties.find(property => property.name === 'product_type');
    var product = this.products[i];
    var item = new QuoteItem(property.description, product.order_num, product.model, product.description, 1, false, 0);
    this.cart.add(item);
  }
}