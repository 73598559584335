import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { SidebarComponent } from 'src/app/components/sidebar/sidebar.component';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { Observable, of } from 'rxjs';
import { QuoteItem } from 'src/app/models/quote-item';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/api/api.service';
import { CartService } from 'src/app/services/cart.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { Alerts } from 'src/app/utils/alerts';

declare var jQuery: any;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, AfterViewInit {
  // Products with Accessories and Warranties
  accessories: string[] = ['matrix_120', 'matrix_220', 'matrix_300n', 'matrix_410n', 'smart_camera', 'processor', 'processor_cameras', 'processors_lights', 'vision_sensor', 'light_curtain', 'portal_reader'];
  
  warranties: string[] = ['laser_marker', 'falcon_x4', 'memor_1', 'memor_10', 'memor_20', 'rhino_2', 'skorpio_x4', 'task_book', 'hand_scanner', 'desk_reader'];
  
  @ViewChild('sidebar', { static: false }) sidebar: SidebarComponent;

  @ViewChild('modal_1', { static: false }) modal_1: ModalComponent;

  @ViewChild('modal_2', { static: false }) modal_2: ModalComponent;

  observable$: Observable<QuoteItem[]> = of([]);

  productsWithAccessories: QuoteItem[] = [];

  productsWithWarranties: QuoteItem[] = [];

  firstModal: boolean = false;

  clicked: boolean = false;

  items: QuoteItem[] = [];

  Warranties: any[] = [];

  success: string = '';

  cartForm: FormGroup;

  title: string = '';

  constructor(private api: ApiService, private builder: FormBuilder, private cart: CartService, private spinner: NgxSpinnerService) {
    this.observable$ = this.cart.getCart();
    this.observable$.subscribe(_ => this.items = _);

    this.cartForm = this.builder.group({
      name: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(50)]],
      mail: ['', [Validators.required, Validators.email, Validators.minLength(15), Validators.maxLength(50)]],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)]],
      company: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
    });
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void { }

  hasThisWarranty(index: number, value: number): boolean {
    var product = this.productsWithWarranties[index];
    return product.warranty === value;
  }

  modifyQuantity(key: number, element: any) {
    var button = jQuery(element);
    var oldVal = button.parent().find('input').val();
    if (button.hasClass('inc')) {
      var newVal = parseInt(oldVal, 10) + 1;
    } else {
      // Don't allow decrementing below one
      if (oldVal > 1) {
        var newVal = parseInt(oldVal, 10) - 1;
      } else {
        newVal = 1;
      }
    }
    button.parent().find('input').val(newVal);
    this.updateQuantity(key, button.parent().find('input'));
  }

  updateQuantity(key: number, element: any): void {
    var input = jQuery(element);
    var value = parseInt(input.val(), 10);
    if (isNaN(value) || value < 1) {
      value = 1;
    }
    input.val(value);
    this.items[key].quantity = value;
  }

  updateAccessories(element: any, index: number): void {
    var product = this.productsWithAccessories[index];
    var x = this.items.findIndex(item => item.order_num === product.order_num);
    this.items[x].accessories = jQuery(element).is(':checked');
  }

  updateWarranties(element: any, index: number): void {
    var product = this.productsWithWarranties[index];
    var x = this.items.findIndex(item => item.order_num === product.order_num);
    var value = Number.parseInt(jQuery(element).val(), 10);
    this.items[x].warranty = value;
  }

  removeItem(i: number): void {
    var item: QuoteItem = this.items[i];
    this.cart.remove(item);
  }

  confirmRemoval(): void {
    this.modal_1.open();
  }

  removeItems(): void {
    this.modal_1.close();
    this.cart.removeAll(true);
  }

  openForm(): void {
    this.title = 'Generar Cotización';
    this.items.forEach(item => {
      if (this.accessories.includes(item.line)) {
        this.productsWithAccessories.push(item);
      }
      if (this.warranties.includes(item.line)) {
        this.productsWithWarranties.push(item);
      }
    });
    if (this.productsWithAccessories.length || this.productsWithWarranties.length) {
      this.firstModal = true;
      this.title = 'Accesorios & Garantías';
      if (this.productsWithWarranties.length) {
        this.spinner.show();
        var form: FormData = new FormData();
        form.append('products', JSON.stringify(this.productsWithWarranties));
        this.api.post('get-warranties', form).pipe(finalize(() => {
          this.spinner.hide();
        })).subscribe((response: any) => {
          this.Warranties = response;
          this.modal_2.open();
        }, () => {
          Alerts.showDangerAlert();
        });
      } else {
        this.modal_2.open();
      }
    } else {
      this.modal_2.open();
    }
  }

  resetForm(): void {
    this.title = '';
    this.modal_1.close();
    this.modal_2.close();
    this.clicked = false;
    this.Warranties = [];
    this.cartForm.reset();
    this.firstModal = false;
    this.productsWithWarranties = [];
    this.productsWithAccessories = [];
  }

  continueQuotation(): void {
    this.firstModal = false;
    this.title = 'Generar Cotización';
  }

  generateQuotation(): void {
    this.clicked = false;
    if (this.cartForm.invalid) {
      this.clicked = true;
    } else {
      this.spinner.show();
      var form: FormData = new FormData();
      form.append('name', this.cartForm.controls['name'].value);
      form.append('mail', this.cartForm.controls['mail'].value);
      form.append('phone', this.cartForm.controls['phone'].value);
      form.append('company', this.cartForm.controls['company'].value);
      form.append('products', JSON.stringify(this.items));
      this.api.post('request-quotation', form).pipe(finalize(() => {
        this.spinner.hide();
      })).subscribe((response: any) => {
        this.resetForm();
        this.cart.removeAll(false);
        this.success = response.join(';');
      }, () => {
        Alerts.showDangerAlert();
      });
    }
  }
}