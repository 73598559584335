import { Component, OnInit, ViewChild } from '@angular/core';
import { SidebarComponent } from 'src/app/components/sidebar/sidebar.component';
import { ApiService } from 'src/app/api/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { Alerts } from 'src/app/utils/alerts';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  @ViewChild('sidebar', { static: false }) sidebar: SidebarComponent;

  clicked: boolean = false;

  contactForm: FormGroup;

  constructor(private api: ApiService, private builder: FormBuilder, private spinner: NgxSpinnerService) {
    this.contactForm = this.builder.group({
      name: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(50)]],
      mail: ['', [Validators.required, Validators.email, Validators.minLength(15), Validators.maxLength(50)]],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)]],
      message: ['', [Validators.required]],
    });
  }

  ngOnInit(): void { }

  sendMessage(): void {
    this.clicked = false;
    if (this.contactForm.invalid) {
      this.clicked = true;
    } else {
      this.spinner.show();
      let keys = Object.keys(this.contactForm.value);
      let form: FormData = new FormData();
      keys.forEach(key => {
        form.append(key, this.contactForm.value[key]);
      });
      this.api.post('send-message', form).pipe(finalize(() => {
        this.spinner.hide();
      })).subscribe((response: any) => {
        this.contactForm.reset();
        Alerts.showSuccessAlert(response.join(';'));
      }, () => {
        Alerts.showDangerAlert();
      });
    }
  }
}