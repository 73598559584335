import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { QuoteItem } from '../models/quote-item';
import { Alerts } from '../utils/alerts';

declare var jQuery: any;

@Injectable({
  providedIn: 'root'
})
export class CartService {
  behavior: BehaviorSubject<QuoteItem[]> = new BehaviorSubject([]);
  items: QuoteItem[] = [];

  constructor() {
    if (localStorage.getItem('items')) {
      this.behavior.next(JSON.parse(localStorage.getItem('items')));
    } else {
      this.behavior.next(this.items);
    }
    this.behavior.subscribe(_ => this.items = _);
  }

  add(item: QuoteItem): void {
    if (this.items.filter(element => element.order_num === item.order_num).length > 0) {
      Alerts.showWarningAlert('Este producto ya se encuentra en el carrito.');
      return;
    }
    this.items.push(item);
    this.behavior.next(this.items);
    localStorage.setItem('items', JSON.stringify(this.items));
    Alerts.showInfoAlert('Has agregado el producto al carrito.');
    jQuery('#shopping-icon').effect('shake', { direction: 'left', times: 5, distance: 3 }, 1000);
  }

  getCart(): Observable<QuoteItem[]> {
    return this.behavior;
  }

  remove(item: QuoteItem): void {
    this.items = this.items.filter(element => element.order_num != item.order_num);
    this.behavior.next(this.items);
    localStorage.setItem('items', JSON.stringify(this.items));
    Alerts.showInfoAlert('Has removido el producto del carrito.');
  }

  removeAll(showAlert: boolean): void {
    this.items = [];
    this.behavior.next(this.items);
    localStorage.setItem('items', JSON.stringify(this.items));
    if (showAlert) {
      Alerts.showInfoAlert('Has removido todos los productos del carrito.');
    }
  }
}