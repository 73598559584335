import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { SidebarComponent } from 'src/app/components/sidebar/sidebar.component';
import { GlobalService } from 'src/app/services/global.service';

declare var jQuery: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild('sidebar', { static: false }) sidebar: SidebarComponent;

  constructor(private global: GlobalService) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    jQuery('.blog-mesonry').imagesLoaded(function () {
      jQuery('.mesonry-list').isotope({
        layoutMode: 'masonry',
        percentPosition: true,
        itemSelector: '.grid-item',
        transitionDuration: '0.7s',
        masonry: {
          columnWidth: '.resizer'
        }
      });

      jQuery('.mesonry-list').isotope();
    });

    jQuery('.play-video').modalVideo({
      youtube: {
        controls: 1,
        nocookie: true
      }
    });
  }
}